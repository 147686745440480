<template>
    <div>
        <div class="counter-group col">
            <div class="counter counter-lg">
                <small v-if="prepend">{{ prepend }}</small>
                <counter :value="valueFormatter(total_amount)"></counter>
                <small v-if="append">{{ append }}</small>
                <small class="achieve-rate" v-if="total_target">
                    (<counter :value="total_achieved"></counter>% meta)
                </small>
            </div>
            <p>Valor</p>
        </div>
        <div class="row">
            <div class="counter-group col-sm-6">
                <div class="counter">
                    <counter class="counter" :value="valueFormatter(virtual_amount)"></counter>
                    <small v-if="append">{{ append }}</small>
                </div>
                <p>Virtual</p>
            </div>
            <div class="counter-group col-sm-6">
                <div class="counter">
                    <counter class="counter" :value="valueFormatter(physical_amount)"></counter>
                    <small v-if="append">{{ append }}</small>
                </div>
                <p>Física</p>
            </div>
        </div>
        <div class="row">
            <div class="counter-group col-sm-6">
                <div class="counter">
                    <counter :value="total_quantity"></counter>
                </div>
                <p>Quantidade</p>
            </div>
            <div class="counter-group col-sm-6">
                <div class="counter">
                    <small v-if="prepend">{{ prepend }}</small>
                    <counter :value="valueFormatter(total_avg_ticket)"></counter>
                    <small v-if="append">{{ append }}</small>
                </div>
                <p>Ticket médio</p>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .counter-group {
        text-align: center;
    }

    .counter {
        font-size: 25px;
        font-weight: 500;
        color: #666;
        text-align: center;
    }

    .counter-lg {
        font-size: 35px;
    }

    .achieve-rate {
        font-size: 1.5rem;
        padding-left: 1rem;
    }

    @media (max-width: 840px) {
        .achieve-rate {
            display: block;
        }
    }

    @media (min-width: 1000px) {
        .counter {
            font-size: 28px;
        }

        .counter-lg {
            font-size: 45px;
        }
    }

    @media (min-width: 1700px) {
        .counter {
            font-size: 35px;
        }

        .counter-lg {
            font-size: 55px;
        }
    }
</style>

<script>
    import Counter from "./shared/Counter";
    import DataHelper from "../../helpers/DataHelper";

    export default {
        components: {
            Counter
        },
        props: {
            total_target: {
                type: Number,
                required: true
            },
            physical_quantity: {
                type: Number,
                required: true
            },
            virtual_quantity: {
                type: Number,
                required: true
            },
            physical_amount: {
                type: Number,
                required: true
            },
            virtual_amount: {
                type: Number,
                required: true
            },
            duration: {
                type: Number,
                default: 4
            },
            valueFormatter: {
                type: Function,
                default: (v) => {
                    return v;
                }
            },
            append: {
                type: String,
                default: ''
            },
            prepend: {
                type: String,
                default: ''
            }
        },
        computed: {
            total_avg_ticket() {
                return DataHelper.roundNumber(this.total_amount / this.total_quantity, 0);
            },
            total_amount() {
                return this.physical_amount + this.virtual_amount;
            },
            total_quantity() {
                return this.physical_quantity + this.virtual_quantity;
            },
            total_achieved() {
                return Math.floor(this.total_amount / this.total_target * 100);
            }
        }
    }
</script>

