import Cookies from 'js-cookie';
import {mapMutations} from "vuex";

export default {
    data() {
        return {
            common: {
                darkMode: false
            },
            ctrl: {
                darkModeCookie: 'dark_mode',
                darkModeCookieEnabled: '1',
                darkModeDefault: false
            }
        }
    },
    methods: {
        ...mapMutations([
            '_store_darkMode'
        ]),
        _common_checkDarkMode() {
            const cookieValue = Cookies.get(this.ctrl.darkModeCookie);
            this.common.darkMode = cookieValue
                ? (cookieValue ===  this.ctrl.darkModeCookieEnabled)
                : this.ctrl.darkModeDefault;
            this._common_toggleMode(false);
        },
        _common_toggleMode(toggle = true) {
            if (toggle) {
                this.common.darkMode = !this.common.darkMode;
                const cookieValue = this.common.darkMode
                    ? this.ctrl.darkModeCookieEnabled
                    : '';
                Cookies.set(this.ctrl.darkModeCookie, cookieValue);
            }
            this._store_darkMode(this.common.darkMode);
            document.body.classList.toggle('dark-mode', this.common.darkMode);
        }
    },
    mounted() {
        this._common_checkDarkMode();
    }
}
