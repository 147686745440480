<template>
    <main class="app-theme">

        <navbar-app :dark-mode-item="true"
                    @toggle-dark-mode="_common_toggleMode"/>

        <template v-if="dateCampaign.isReady">

            <not-started-campaign-app v-if="!dateCampaign.started"/>

            <ended-campaign-app v-else-if="dateCampaign.ended"/>

            <template v-else>
                <notifications classes="notify-new-donation"
                               group="new-donation"
                               position="top left"
                               :width="notificationWidth"/>

                <div class="cards-container">

                    <div v-for="(result, group) in supermarket.results" :key="group" class="card three-cols">
                        <div class="card-body center max-height-charts">
                            <h5 class="card-title d-flex justify-content-between align-items-center">
                                Grupo {{ group }}
                                <span class="text-muted group-amount">
                                    {{ result.amount | formatDecimal(false) }}Kg
                                </span>
                                <help-popover class="popover-text super-market-popover">
                                    <div>
                                        <table class="table table-borderless">
                                            <tr v-for="(superMarket, key) in filterMarketByGroup(group)" :key="key">
                                                <th>{{ key }}</th>
                                                <td>{{ superMarket.name }}</td>
                                            </tr>
                                        </table>
                                    </div>
                                </help-popover>
                            </h5>
                            <bar-donation-amount-by-metadata v-if="Object.keys(result.teams).length"
                                                             :metadata="{ name: result.teams }"
                                                             :name="'name'"
                                                             :top="100"
                                                             :right-padding="120"
                                                             :datalabels="marketGroupDataLabels()"
                                                             :formatter="supermarket.formatters.marketResult"/>
                            <p v-else-if="!campaign.init" class="text-center text-muted">
                                <font-awesome-icon icon="circle-notch" spin size="3x"/>
                            </p>
                            <p v-else class="text-center"><em>Não há doações</em></p>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-body center mobile-no-max-height">
                            <h5 class="card-title">Total Arrecadado</h5>
                            <template v-if="campaign.aggregate && campaign.aggregate.count_items && campaign.aggregate.sum_amount">
                                <five-counters v-if="supermarket.physical_donations.enabled"
                                               :virtual_amount="campaign.aggregate.sum_amount"
                                               :virtual_quantity="campaign.aggregate.count_items"
                                               :physical_amount="supermarket.physical_donations.amount"
                                               :physical_quantity="supermarket.physical_donations.quantity"
                                               :total_target="target"
                                               :value-formatter="supermarket.formatters.marketAmount"
                                               append=" Kg"></five-counters>
                                <three-counters v-else
                                                :amount="campaign.aggregate.sum_amount"
                                                :quantity="campaign.aggregate.count_items"
                                                :amount_target="target"
                                                :value-formatter="supermarket.formatters.marketAmount"
                                                append=" Kg"></three-counters>
                            </template>
                            <p v-else-if="!campaign.init" class="text-center text-muted">
                                <font-awesome-icon icon="circle-notch" spin size="3x"/>
                            </p>
                            <p v-else class="text-center"><em>Não há doações</em></p>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-body center">
                            <h5 class="card-title">Últimas Doações</h5>
                            <latest-donations v-if="campaign.aggregate && campaign.aggregate.latest"
                                              :donations="campaign.aggregate.latest"
                                              :metadata="'nome_loja'"
                                              :metadata-formatter="supermarket.formatters.marketName"
                                              :value-formatter="supermarket.formatters.marketAmountWithAppend"
                                              :limit="5"/>
                            <p v-else-if="!campaign.init" class="text-center text-muted">
                                <font-awesome-icon icon="circle-notch" spin size="3x"/>
                            </p>
                            <p v-else class="text-center"><em>Não há doações</em></p>
                        </div>
                    </div>

                    <footer>
                        <div class="container">
                            <div class="columns">
                                <p class="message">
                                    Doações realizadas a partir de {{ startCampaign }} com pagamento confirmado
                                </p>
                                <div class="seals">
                                    <div class="column">
                                        <a class="seal" href="https://www.amigosdobem.org/transparencia/" target="_blank">
                                            <img src="@/assets/img/logos/logo-ey.svg" height="100" alt="">
                                        </a>
                                        <a class="seal" href="https://www.premiomelhores.org/conheca-as-melhores-ongs-de-2022" target="_blank">
                                            <img src="@/assets/img/logos/logo-melhores-ongs.svg" height="100" alt="">
                                        </a>
                                        <a class="seal" href="https://www.institutodoar.org/certificado-amigos-do-bem/" target="_blank">
                                            <img src="@/assets/img/logos/logo-padrao-doar-2.svg" height="100" alt="">
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </footer>
                </div>

            </template>
        </template>
    </main>
</template>

<style scoped>
footer {
    background: #333;
    color: #fff;
    min-height: 100px;
    padding: 20px 0;
    width: 100%;
}

footer .columns {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

footer .columns .message {
    display: flex;
    justify-content: space-between;
    font-style: italic;
    font-size: .9rem;
    text-align: center;
    padding: 0 3%;
    margin: 0;
    gap: 30px;
    max-width: 350px;
}

footer .columns .seals {
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    gap: 30px;
}

footer .columns .seals .column {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}

footer .columns .seal {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    gap: 5%;
}

footer .columns .seal img {
    width: auto;
    max-height: 75px;
}

@media (max-width: 991px) {
    footer .columns .seal img {
        max-height: 70px;
    }
}

@media (max-width: 768px) {
    footer .columns {
        flex-direction: column;
    }

    footer .columns .message {
        margin-bottom: 2rem;
        padding: 0 1%;
    }

    footer .columns .seals {
        flex-direction: column;
    }
}
</style>

<style>
@media (max-width: 700px) {
    main.app-theme .card-body.max-height-charts {
        max-height: 400px;
    }

    main.app-theme .card-body.max-height-charts div {
        max-height: 280px;
    }
}

@media (min-width: 701px) {
    main.app-theme .card-body.max-height-charts div {
        max-height: 280px;
        overflow-y: auto;
        overflow-x: hidden;
    }
}

@media (min-width: 1500px) {
    main.app-theme .card-body.max-height-charts div {
        max-height: 300px;
    }
}

.super-market-popover .popper > div {
    max-height: 300px !important;
    overflow-y: auto;
    overflow-x: hidden;
}

.super-market-popover .popper .table th,
.super-market-popover .popper .table td {
    padding: 0.25rem;
}

.super-market-popover .popper .table td {
    font-size: 0.8rem;
    vertical-align: middle;
}

.group-amount {
    font-size: 1.1rem;
}

@media (max-width: 400px) {
    .group-amount {
        font-size: .9rem;
    }
}
</style>

<script>
import CampaignMixin from "../../mixins/CampaignMixin";
import NavbarApp from "../../components/navbars/NavbarApp";
import LatestDonations from "../../components/lists/LatestDonations";
import BarDonationAmountByMetadata from "../../components/charts/BarDonationAmountByMetadata";
import KilogramHelper from "../../helpers/KilogramHelper";
import SuperMarketHelper from "../../helpers/SuperMarketHelper";
import HelpPopover from "../../components/popover/HelpPopover";
import ThreeCounters from "@/components/counters/ThreeCounters";
import Formatter from "../../helpers/Formatter";
import ConfettiMixin from "@/mixins/ConfettiMixin";
import FiveCounters from "@/components/counters/FiveCounters";
import moment from "moment";
import EndedCampaignApp from "@/components/warnings/EndedCampaignApp";
import NotStartedCampaignApp from "@/components/warnings/NotStartedCampaignApp";
import DarkModeMixin from "@/mixins/DarkModeMixin";

export default {
    mixins: [CampaignMixin, ConfettiMixin, DarkModeMixin],
    components: {
        NotStartedCampaignApp,
        EndedCampaignApp,
        BarDonationAmountByMetadata,
        HelpPopover,
        FiveCounters,
        LatestDonations,
        NavbarApp,
        ThreeCounters
    },
    data() {
        return {
            supermarket: {
                results: {},
                physical_donations: {},
                previous_result: {},
                start_campaign: null,
                formatters: {
                    marketResult: (key, value) => {
                        return [
                            SuperMarketHelper.getShortName(key),
                            KilogramHelper.convertToKilogram(value)
                        ];
                    },
                    marketName: (name) => SuperMarketHelper.getShortName(name),
                    marketAmount: (float) => KilogramHelper.convertToKilogram(float),
                    marketAmountWithAppend: (float) => KilogramHelper.convertToKilogram(float, true)
                }
            }
        }
    },
    methods: {
        _hook_onNewDonationCreated(donation) {
            const amount = KilogramHelper.convertToKilogram(donation.amount, true);

            this.$notify({
                duration: 2000,
                group: 'new-donation',
                title: 'Doação Recebida',
                text: `${donation.donor.name} doou ${amount}`
            });
        },
        setSettingsChangeEvents() {
            this._common_getSettings()
                .on('value', s => {
                    const settings = s.val();
                    this.supermarket.physical_donations = settings.custom.physical_donations;
                    this.supermarket.previous_result = settings.custom.previous_result;
                    this.supermarket.start_campaign = settings.custom.start_campaign;
                });
        },
        filterMarketByGroup(group) {
            return SuperMarketHelper.getListByGroup(group);
        },
        updateResults() {
            if (!this.isDataReady) return {};

            const metaName = 'nome_loja';
            const metaData = this.campaign.aggregate.metadata[metaName] || {};
            const groups = SuperMarketHelper.getGroups();

            groups.forEach(group => {
                const supermarkets = Object.keys(SuperMarketHelper.getListByGroup(group));
                const filtered = Object.entries(metaData).filter(([k]) => supermarkets.includes(k));
                const teams = Object.fromEntries(filtered);
                const sum = Object.values(teams).reduce((acc, item) => acc + item, 0);

                this.supermarket.results[group] ={
                    teams: teams,
                    amount: KilogramHelper.convertToKilogram(sum)
                }
            })
        },
        marketGroupDataLabels() {
            return {
                labels: {
                    index: {},
                    comparison: {
                        align: 'right',
                        anchor: 'end',
                        offset: 50,
                        color: function (context) {
                            const i = context.dataIndex;
                            const current = context.dataset.data[i];
                            const marketAbbr = context.chart.data.labels[i];
                            const previous = SuperMarketHelper.getPreviousResult(marketAbbr);
                            if (!previous) return '';
                            const comparison = (current / previous - 1) * 100;

                            return comparison > 0 ? 'green' : '#999';
                        },
                        formatter: function (value, context) {
                            const i = context.dataIndex;
                            const currentVal = context.dataset.data[i];
                            const marketAbbr = context.chart.data.labels[i];
                            const previous = SuperMarketHelper.getPreviousResult(marketAbbr);

                            if (!previous) return '';

                            const comparison = (currentVal / previous - 1) * 100;
                            const signal = comparison > 0 ? '+' : '';
                            const percentage = Formatter.formatDecimal(comparison, 0);
                            const previousVal = Formatter.formatDecimal(previous, 0);

                            return `(${previousVal} ${signal}${percentage}%)`;
                        }
                    }
                }
            }
        }
    },
    computed: {
        notificationWidth() {
            return (window.innerWidth < 400 ? 300 : 400) + 'px';
        },
        target() {
            return this._store_getAuthSettingsProp('target');
        },
        startCampaign() {
            const startDate = this.supermarket.start_campaign;
            return startDate ? moment(startDate).format('DD/MM/YYYY') : null;
        },
        isDataReady() {
            return this.campaign.aggregate && this.campaign.aggregate.metadata && this.supermarket.previous_result;
        },
        totalAmount() {
            return this.supermarket.physical_donations.amount +
                (this.campaign.aggregate ? this.campaign.aggregate.sum_amount : 0);
        },
        targetAchieved() {
            return this.target > 0 && this.totalAmount >= this.target;
        }
    },
    watch: {
        targetAchieved() {
            this._confetti_throw();
        },
        'supermarket.previous_result': function(val) {
            SuperMarketHelper.setResult(val);
            this.updateResults();
        },
        'campaign.aggregate.metadata': {
            handler() {
                this.updateResults();
            },
            deep: true
        }
    },
    created() {
        this.setSettingsChangeEvents();
        this.updateResults();
    }
}
</script>

