<template>
    <popper trigger="clickToToggle"
            :options="{'placement' : 'bottom-end'}"
            @show="display = true"
            @hide="display = false">
        <div class="popper">
            <slot></slot>
        </div>

        <button type="button" class="btn btn-link" slot="reference">
            <font-awesome-layers v-show="!display" class="stack">
                <font-awesome-icon :icon="['far', 'circle']"/>
                <font-awesome-icon icon="exclamation" transform="shrink-6" />
            </font-awesome-layers>
            <font-awesome-layers v-show="display" class="stack">
                <font-awesome-icon :icon="['far', 'circle']"/>
                <font-awesome-icon icon="times" transform="shrink-6" />
            </font-awesome-layers>
        </button>
    </popper>
</template>

<style scoped>
button {
    padding: 0;
}

button .stack {
    vertical-align: top;
    color: #a8a8a8;
    font-size: 1.2rem
}

button:hover .stack {
    color: #666;
}

div.popper {
    max-height: none !important;
}
</style>

<script>
import Popper from 'vue-popperjs';

export default {
    components: {
        'popper': Popper
    },
    props: {
        title: {
            type: String,
            default: 'Ajuda'
        }
    },
    data() {
        return {
            display: false
        }
    }
}
</script>

