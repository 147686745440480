import VueConfetti from 'vue-confetti';
import moment from "moment";
import vue from "vue";

vue.use(VueConfetti);

export default {
    data() {
        return {
            confetti: {
                enabled: true,
                second: 0,
                thrown: false,
            }
        }
    },
    methods: {
        _confetti_throw(multiple = false) {
            if (!multiple && this.confetti.thrown) return;

            this.$confetti.start({
                defaultDropRate: 10,
            });
            window.setTimeout(() => {
                this.$confetti.stop();
            }, 10000)
        },
        _confetti_observe() {
            if (!this.confetti.enabled) return;

            window.setInterval(() => {
                this.confetti.second = moment().seconds();
            }, 1000);
        },
    },
    created() {
        this._confetti_observe();
    }
}
