import ArrayHelper from "@/helpers/ArrayHelper";

export default class SuperMarketHelper {

    static setResult(list) {
        this.list = list;
    }

    static getResult() {
        return this.list || {};
    }

    static getPreviousResult(abbr) {
        const markets = this.getResult();
        const market = markets[abbr] || '';

        return market ? market.previous : 0;
    }

    static getGroups() {
        const groups = Object.values(this.getResult()).map(team => team.group);
        return ArrayHelper.unique(groups);
    }

    static getListByGroup(group) {
        // eslint-disable-next-line no-unused-vars
        const filtered = Object.entries(this.getResult()).filter(([k, v]) => {
            return v.group === group;
        });

        return Object.fromEntries(filtered);
    }

    static getShortName(name) {
        const parts = name.split('|');
        const lastPart = parts[parts.length - 1];

        return lastPart.trim();
    }
}
